import React from "react";
import Carousel from '../Carousel.js';
import Contactus from '../Contactus/Contactus.js'
import Cateogry from '../Category/Cateogry.js';
import Product from '../products/Product.js';
import Aboutus from '../AboutUs/Aboutus.js';
import Treatment from "../Product treatments/Treatment.js";
import { assets } from "../products/Assets/prodimg/assets.js";

import FAQ from "../FAQ/FAQ.js";

const Home = () => {
    const images = [
        assets.homebanner1,
        assets.homebanner2,
        assets.homebanner5,
        assets.homebanner3,
        assets.homebanner4,

    ];
    return (
        <>
           {/* <Popup/> */}
            <Carousel images={images} />
            <Cateogry />
            <Product />
            <Treatment />
            {/* <div style={{ display: 'flex', gap: '0', flexDirection: 'column' }}>
                <img src={img22} style={{ maxWidth: '100%', minWidth: '100%' }} alt="exotic-veda" loading="lazy" />
            </div> */}
            <Aboutus />
            <FAQ/>
            <Contactus />

        </>
    )
}

export default Home;