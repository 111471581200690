// Product.js
import React from 'react';
import { productData } from './recipdata';
import Price from './Price';
import AdditionalInfo from './AdditionalInfo';

const Product = ({ productId }) => {
  const product = productData[productId];

  if (!product) return <p>Product not found</p>;

  return (
    <div className="product-details">
      <h1 style={{ textAlign: 'justify', fontSize: '18px',fontFamily:'serif'}}>{product.name}</h1>

      {/* Automatically Calculated Price Section */}
      <Price currentPrice={product.price.current} originalPrice={product.price.original} />

      <h2 style={{textAlign:'start',fontFamily:'serif'}}>Product Description</h2>
      <ul style={{ textAlign: 'justify' ,listStyle:'circle'}}>
        {product.description.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <AdditionalInfo />
    </div>
  );
};

export default Product;
